<template>
  <div class="row">
    <ed-table
    :key="$root.$session.versao"
       strModel="Cadastro/Funcionario"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      :config="{ searchName: 'Pesquisar funcionário por CPF ou Nome' }"
      @search="getFuncionario"
    />
  </div>
</template>

<script>
import {
  EdTable,
  EdInputAutoComplete,
  EdModal,
} from "@/components/common/form";
import EdFuncionario from "@/components/cadastro/funcionario/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable, EdInputAutoComplete, EdModal, EdFuncionario },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  mounted() {
    this.getFuncionario();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroFuncionario: false,
      arrayHeaders: [
        {
          text: "CPF",
          sortable: true,
          value: "strCpf",
        },
        {
          text: "Nome",
          sortable: true,
          collapse: 60,
          value: "strNome",
        },
        {
          text: "Empresa",
          sortable: true,
          value: "strNomeEmpresa",
          collapse: 100,
        },
        {
          text: "Função",
          sortable: true,
          value: "intFuncaoId",
        },
        {
          text: "Unidade",
          sortable: true,
          value: "intCadastroGeralUnidadeId",
        },
        {
          text: "Setor",
          sortable: true,
          value: "intCadastroGeralSetorId",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formData: {
        intCadastroGeralEmpresaId: null,
      },
      formFiltros:{
        boolMostrarEmpresa: true,
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    onRegister() {
      this.$router.push({
        name: "cadastro.funcionario.register",
        params: {
          //intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
        },
      });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.funcionario.edit",
        params: {
          //intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
          intId: data.intId,
        },
      });
    },

    onInserirConsulta(data){
       this.$router.push({
        name: "consulta.register",
        params: {
          intFuncionarioId: data.intId,
        },
      });
    },

    getFuncionario() {
      this.loading = true;
      this.arrayRows = [];


      this.$root.$request
        .get(
          "Cadastro/Funcionario",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objFuncionario) => {
              let strNomeEmpresa = objFuncionario.empresa ? objFuncionario.empresa.strNome : '---';

              let item = {
                intId: objFuncionario.intId,
                strCpf: objFuncionario.strCpf,
                strNome: objFuncionario.strNome,
                strTelefone: objFuncionario.strTelefone,
                strNomeEmpresa: strNomeEmpresa,
                intFuncaoId: objFuncionario.funcao
                  ? objFuncionario.funcao.strNome
                  : "---",
                intCadastroGeralUnidadeId: objFuncionario.unidade
                  ? objFuncionario.unidade.strNome
                  : "---",
                intCadastroGeralSetorId: objFuncionario.setor
                  ? objFuncionario.setor.strNome
                  : "---",
                _item: objFuncionario,
                buttons:[
                  {
                    title:'Inserir consulta para esse funcionário',
                    color:'blue',
                    icone:this.$utilidade.getIcone('consulta'),
                    click:this.onInserirConsulta
                  }
                ]
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
